<template>
  <section v-if="concerns && !slice.primary.hide_section" class="home__concerns large-pad">
    <div class="home__concerns__inner">
      <div class="home__concerns__top row">
        <div class="home__concerns__top__left column">
          <h2 class="h5">{{ slice.primary.title.text ? getTitle(slice.primary.title) : 'Concerns' }}</h2>
        </div>
        <div class="home__concerns__top__right column">
          <ArrowLink to="/shop/concerns" text="All Concerns" />
        </div>
      </div>
      <div class="home__concerns__middle row">
        <div class="home__concerns__middle__left column small-12 xxlarge-3">
          <ul class="side-nav">
            <li v-for="(concern, index) in concerns">
              <button :class="{ 'is-active' : activeConcern == concern._meta.uid }" @click="activeConcern = concern._meta.uid">
                {{ concern.title | getTitle }}
              </button>
            </li>
          </ul>
          <span v-if="noteText || noteLinkText" class="note">{{ noteText }}<br /><ArrowLink v-if="noteLinkText" :link="noteLink" size="small" :text="noteLinkText" /></span>
        </div>
        <div class="home__concerns__middle__right column small-12 xxlarge-9">
          <div v-for="concern in concerns">
            <transition class="row">
              <div class="row" :class="{'is-active' : activeConcern == concern._meta.uid}" v-show="activeConcern == concern._meta.uid">
                <div v-if="concern.featured_image" class="column small-6 large-4">
                  <PrismicLazyImage
                    :image="concern.featured_image"
                    :width="350"
                    :height="350"
                    class="square cover"
                  />
                </div>
                <div class="column" :class="concern.featured_image ? 'small-6 large-8' : 'small-10'">
                  <prismic-rich-text v-if="concern.content" :field="concern.content" />
                  <Button v-if="concern.title" :to="`/shop/concerns/${concern._meta.uid}`" :text="concern.title | getTitle" />
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="home__concerns__bottom row">
        <div class="column small-12">
          <Button
            v-for="concern in concerns"
            :key="concern._meta.uid"
            v-if="activeConcern == concern._meta.uid"
            :to="`/shop/concerns/${concern._meta.uid}`"
            :text="concern.title | getTitle"
          />
          <span v-if="noteText || noteLinkText" class="note">{{ noteText }}<br /><ArrowLink v-if="noteLinkText" :link="noteLink" size="small" :text="noteLinkText" /></span>
        </div>
      </div>
      <Seal />
    </div>
  </section>
</template>

<script>
  import { fetchConcernsByUIDS } from '@/prismic/queries';

  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';
  import ArrowLink from '@/components/global/ArrowLink';
  import Button from '@/components/global/Button';
  import Seal from '@/components/global/Seal';

  export default {
    name: 'HomepageConcerns',

    components: {
      PrismicLazyImage,
      ArrowLink,
      Button,
      Seal
    },

    data() {
      return {
        concerns: null,
        activeConcern: null
      }
    },

    props: {
      slice: {
        type: Object,
        required: true,
        default() {
          return {}
        }
      }
    },

    computed: {
      noteText() {
        return this.slice.primary.note_text;
      },
      noteLinkText() {
        return this.slice.primary.note_link_text;
      },
      noteLink() {
        return this.slice.primary.note_link;
      }
    },

    async fetch() {
      const concernUids = this.slice.items.map(item => item.concern.uid);

      try {
        const { data } = await this.$apolloProvider.defaultClient.query({
          query: fetchConcernsByUIDS,
          variables: {
            uids: concernUids
          }
		    });

        const response = data.allConcerns.edges;

        if(response.length) {
          this.concerns = response.map(item => item.node).slice(0, 5);
          this.activeConcern = this.concerns[0]._meta.uid;
        }
      } catch(err) {
        console.log(err);
      }
    }
  }
</script>
